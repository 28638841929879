<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div
  class="flex w-full flex-auto justify-center bg-gray-200 dark:bg-card sm:p-4 md:p-8"
>
  <!-- Navigation -->
  <ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
      class="dark bg-gray-900 print:hidden"
      [mode]="'over'"
      [name]="'mainNavigation'"
      [navigation]="navigation.default"
      [opened]="false"
    >
      <!-- Navigation header hook -->
      <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-20 items-center px-8 pt-6">
          <img class="w-30" src="assets/images/logo/logo-text-on-dark.svg" />
        </div>
      </ng-container>
    </fuse-vertical-navigation>
  </ng-container>

  <!-- Wrapper -->
  <div
    class="flex min-w-0 max-w-360 flex-auto flex-col items-center overflow-hidden shadow-2xl dark:shadow-none sm:rounded-xl"
  >
    <!-- Header -->
    <div
      class="bg-card relative z-49 flex h-16 w-full flex-0 items-center border-b px-4 dark:bg-default sm:h-20 md:px-6 print:hidden"
    >
      <ng-container *ngIf="!isScreenSmall">
        <!-- Logo -->
        <div class="mx-2 flex items-center lg:mr-8">
          <div class="hidden lg:flex">
            <!-- Light version -->
            <img
              class="w-24 dark:hidden"
              src="assets/images/logo/logo-text.svg"
              alt="Logo image"
            />
            <!-- Dark version -->
            <img
              class="hidden w-24 dark:flex"
              src="assets/images/logo/logo-text-on-dark.svg"
              alt="Logo image"
            />
          </div>
          <!-- Small version -->
          <img
            class="flex w-8 lg:hidden"
            src="assets/images/logo/snapsph-logo-only.webp"
            alt="Logo image"
          />
        </div>
        <!-- Horizontal navigation -->
        <fuse-horizontal-navigation
          class="mr-2"
          [name]="'mainNavigation'"
          [navigation]="navigation.horizontal"
        ></fuse-horizontal-navigation>
      </ng-container>
      <!-- Navigation toggle button -->
      <ng-container *ngIf="isScreenSmall">
        <button
          class="mr-2"
          mat-icon-button
          (click)="toggleNavigation('mainNavigation')"
        >
          <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
      </ng-container>
      <!-- Components -->
      <div class="ml-auto flex items-center space-x-1 pl-2 sm:space-x-2">
        <languages></languages>
        <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        <search [appearance]="'bar'"></search>
        <shortcuts></shortcuts>
        <messages></messages>
        <notifications></notifications>
        <user></user>
      </div>
    </div>

    <!-- Content -->
    <div class="bg-default flex w-full flex-auto flex-col">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
      class="bg-card relative z-49 flex h-16 w-full flex-0 items-center justify-start border-t px-6 dark:bg-default sm:h-20 sm:px-8 print:hidden"
    >
      <span class="text-secondary font-medium"
        >Fuse &copy; {{ currentYear }}</span
      >
    </div>
  </div>
</div>
