<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="bg-card dark:bg-gray-900 print:hidden"
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex h-20 items-center justify-center">
      <img
        class="w-8"
        src="assets/images/logo/snapsph-logo-only.webp"
        alt="Logo image"
      />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
  <!-- Header -->
  <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <!-- Navigation toggle button -->
    <button
      class="mr-2"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')"
    >
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <shortcuts></shortcuts>
      <messages></messages>
      <notifications></notifications>
      <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
        <mat-icon
          [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
        ></mat-icon>
      </button>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
  >
    <span class="text-secondary font-medium"
      >Fuse &copy; {{ currentYear }}</span
    >
  </div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
