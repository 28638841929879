/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation'

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
]
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboard',
  },
  {
    id: 'events',
    title: 'Events',
    type: 'aside',
    icon: 'heroicons_outline:chart-pie',
    children: [
      {
        id: 'add-event',
        title: 'Add Event',
        type: 'basic',
        icon: 'heroicons_outline:plus-circle',
        link: '/events/add-event',
      },
      {
        id: 'view-events',
        title: 'View Events',
        type: 'basic',
        icon: 'heroicons_outline:list-bullet',
        link: '/events/view-events',
      },
    ],
  },
]
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
]
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
]
